import { Component, Vue, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import debounce from "lodash.debounce";
import {
  formatMoney,
  copyTextClipboard,
  isEmpty,
  getFullName,
  nestedFreeze,
} from "@helpers";
import { Role } from "@/types/role";
import { TeamActions, TeamGetters } from "@store/modules/team/types";
import {
  profileModule,
  teamModule,
  userModule,
  walletModule,
} from "@store/namespaces";
import { TeamMember } from "@/types/team";
import { Currency } from "@/types/currency";
import { ProfileGetters } from "@store/modules/profile/types";
import { WalletGetters } from "@store/modules/wallet/types";
import { VForm } from "@/types/vuetify";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";
import {
  TableHeaderItems,
  TableHeaderValue,
} from "@/types/components/filter-table-top-bar";
import { HtmlElementId, HtmlElementClassName } from "@/types/element";
import { StepAlias } from "@/types/productTour";
import mockTeamMemberHierarchy from "@/mockData/teamMemberHierarchy";

@Component({
  components: {
    TeamBalance: () => import("../components/TeamBalance/TeamBalance.vue"),
  },
})
export default class TeamMixin extends Vue {
  @Ref("confirmSecondFactorForm")
  private readonly confirmSecondFactorFormRef?: VForm;

  @teamModule.Action("fetchTeamInvite")
  private readonly fetchTeamInviteAction!: TeamActions["fetchTeamInvite"];
  @teamModule.Action("fetchTeamHierarchy")
  private readonly fetchTeamHierarchyAction!: TeamActions["fetchTeamHierarchy"];
  @teamModule.Action("refreshTeamInvite")
  private readonly refreshTeamInviteAction!: TeamActions["refreshTeamInvite"];
  @teamModule.Action("updateTeamMember")
  private readonly updateTeamMemberAction!: TeamActions["updateTeamMember"];
  @teamModule.Getter("totalTeamBalance")
  private readonly totalTeamBalanceGetter!: TeamGetters["totalTeamBalance"];
  @teamModule.Getter("teamHierarchyLoading")
  private readonly teamHierarchyLoadingGetter!: TeamGetters["teamHierarchyLoading"];
  @teamModule.Getter("teamHierarchy")
  private readonly teamHierarchy!: TeamGetters["teamHierarchy"];

  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @userModule.Getter("userIsGhost")
  private readonly userIsGhostGetter!: UserGetters["userIsGhost"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("canViewTeamPage")
  private readonly canViewTeamPageGetter!: ProfileGetters["canViewTeamPage"];
  @profileModule.Getter
  private readonly profileSecondFactorEnabled!: ProfileGetters["profileSecondFactorEnabled"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @walletModule.Getter("walletBalance")
  private readonly walletBalanceGetter!: WalletGetters["walletBalance"];

  private teamHierarchyLoading = false;
  private testValue = [];
  private searchInput = "";
  private deletingEmails: string[] = [];
  private updatingEmails: string[] = [];
  private isSearching = false;

  private forceUpdateUserWebPreferencesKey = Date.now();
  private editedTeamMember = {
    email: "",
    note: "",
    secondFactorCode: "",
  };

  private confirmDialog: {
    showed: boolean;
    loading: boolean;
    data: {
      action: "delete";
      email: string;
    };
  } = {
    showed: false,
    loading: false,
    data: {
      action: "delete",
      email: "",
    },
  };

  private confirmSecondFactorCode:
    | {
        action: "note";
        data: {
          email: string;
          note: string;
        };
      }
    | {
        action: "delete";
        data: {
          email: string;
        };
      }
    | null = null;

  private itemsPerPage = 15;
  private searchStr = "";
  private teamHierarchyReloadKey = Date.now();
  // private teamHierarchy: TeamMember | null = null;
  private topBarTableHeaderValue: Readonly<TableHeaderValue> = [];
  private inviteLink = {
    loading: false,
    code: "",
    expireAt: 0,
  };

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private get canViewInviteUrl() {
    return (
      !this.userIsGhostGetter && !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT])
    );
  }

  private get canViewTeamPage() {
    return this.isShowedMainIntro || this.canViewTeamPageGetter;
  }

  private get usedMockData() {
    return (
      this.isShowedMainIntro &&
      [StepAlias.MOCK_TEAM_LIST, StepAlias.MOCK_TEAM_ACTIONS].includes(
        this.$productTour.activeIntro.step.alias as StepAlias
      )
    );
  }

  protected get loadingTeam() {
    return this.teamHierarchyLoadingGetter || this.teamHierarchyLoading;
  }

  protected get loading(): boolean {
    return (
      this.isSearching ||
      this.loadingTeam ||
      this.deletingEmails.length > 0 ||
      this.updatingEmails.length > 0
    );
  }

  private get headers() {
    const headers: (DataTableHeader & {
      enabled?: boolean;
    })[] = [
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.name"),
        value: "name",
        sortable: false,
        enabled: !this.$vuetify.breakpoint.mobile,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.role"),
        value: "authority",
        sortable: false,
        enabled: this.userHasRoleGetter([
          Role.ROLE_ACCOUNTANT,
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
        ]),
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.status"),
        value: "enabled",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.number_of_cards"
        ),
        value: "numberOfCards",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.number_of_active_cards"
        ),
        value: "numberOfActiveCards",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.expenditure"
        ),
        value: "spend",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.balance"),
        value: "balance",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.card_balance"
        ),
        value: "cardBalance",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.supervisor"
        ),
        value: "parentMember",
        sortable: false,
        align: "center",
        enabled: this.$vuetify.breakpoint.mobile,
      },
      {
        text: "",
        value: "action",
        align: "end",
        sortable: false,
        enabled: !this.userIsGhostGetter && !this.$vuetify.breakpoint.mobile,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.delete"),
        value: "delete-action",
        sortable: false,
        enabled:
          this.$vuetify.breakpoint.mobile && this.isShowTeamMemberActionsMenu,
        filter: (_value, _search, item: TeamMember) => {
          return this.checkAccessTeamMemberActions(item);
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.auto_refill.title"),
        value: "autorefill-action",
        sortable: false,
        enabled: this.$vuetify.breakpoint.mobile && this.isShowAutoRefillMenu,
        filter: (_value, _search, item: TeamMember) => {
          return this.checkCanAutoRefill(item);
        },
      },
    ];

    return headers.filter(({ enabled }) => enabled !== false);
  }

  private get htmlElementClassName() {
    return {
      teamWalletTransferFunds: HtmlElementClassName.teamWalletTransferFunds,
      teamCardIssueLimit: HtmlElementClassName.teamCardIssueLimit,
      teamActions: HtmlElementClassName.teamActions,
    };
  }

  private get htmlElementId() {
    return {
      teamInviteUrlField: HtmlElementId.teamInviteUrlField,
    };
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
    };
  }

  private get excludeTopBarHeaders() {
    return ["name", "delete-action", "action"];
  }

  private get topBarTableHeaderItems() {
    const items: TableHeaderItems = this.headers.filter(
      ({ value }) => !this.excludeTopBarHeaders.includes(value)
    );

    return items;
  }

  private get tableHeaders() {
    if (this.usedMockData) {
      return this.headers;
    }

    const topBarTableHeaderValue = this.topBarTableHeaderValue;

    return this.headers.filter(
      ({ value }) =>
        this.excludeTopBarHeaders.includes(value) ||
        topBarTableHeaderValue.some(
          ({ columnName, selected }) => columnName === value && selected
        )
    );
  }

  private get inviteUrl() {
    if (!this.inviteLink.code) return "";

    const { href } = this.$router.resolve({ name: "sign-up" });

    return `${window.location.origin}${href}?invite=${this.inviteLink.code}`;
  }

  private get teamBalance() {
    return formatMoney({
      value: this.totalTeamBalanceGetter,
      currency: Currency.USD,
      maximumFractionDigits: 2,
    });
  }

  private get flatTeamHierarchy() {
    const teamHierarchy = this.usedMockData
      ? mockTeamMemberHierarchy
      : this.teamHierarchy;

    if (!teamHierarchy) return [];

    const searchStr = this.searchStr.trim().toLowerCase();

    const filterTeamMembers = (value?: Readonly<TeamMember[]> | null) => {
      return value?.reduce<TeamMember[]>((acc, item) => {
        const members = filterTeamMembers(item.members);

        const searched =
          !searchStr ||
          item.email.includes(searchStr) ||
          item.note?.toLowerCase().includes(searchStr) ||
          this.getTeamMemberName(item).toLowerCase().includes(searchStr);

        if (members?.length) {
          if (item.enabled) {
            acc.unshift({ ...item, members });
          } else {
            acc.push({ ...item, members });
          }
        } else if (searched) {
          if (item.enabled) {
            acc.unshift({ ...item, members: [] });
          } else {
            acc.push({ ...item, members: [] });
          }
        }

        return acc;
      }, []);
    };

    return this.flatMembers({
      members: filterTeamMembers(teamHierarchy.members),
      membersOfLastParent: false,
      parentMember: teamHierarchy,
    });
  }

  private get items() {
    if (this.isSearching) {
      return [];
    }

    return this.flatTeamHierarchy;
  }

  private get isShowAutoRefillMenu() {
    return !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT]);
  }

  private get isShowTeamMemberActionsMenu() {
    return !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT]);
  }

  private get isShowConfirmSecondFactorCode() {
    return !!this.confirmSecondFactorCode;
  }

  private set isShowConfirmSecondFactorCode(showed) {
    if (!showed) {
      this.confirmSecondFactorCode = null;
    }

    this.editedTeamMember.secondFactorCode = "";
  }

  private get canEditNote() {
    return (
      !this.userIsGhostGetter && !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT])
    );
  }

  private checkCanSetLimit(item: TeamMember) {
    return (
      item.enabled &&
      !this.userIsGhostGetter &&
      !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT])
    );
  }

  private checkCanTransferMoney() {
    return (
      !this.userIsGhostGetter && !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT])
    );
  }

  private checkCanAutoRefill(item: TeamMember) {
    return item.enabled && this.isShowAutoRefillMenu;
  }

  private checkAccessTeamMemberActions(item: TeamMember) {
    return item.enabled && this.isShowTeamMemberActionsMenu;
  }

  private flatMembers({
    nestingLevel = 1,
    flatIndex = 0,
    members,
    parentMember,
    membersOfLastParent = false,
  }: {
    nestingLevel?: number;
    flatIndex?: number;
    parentMember?: TeamMember;
    members?: TeamMember[] | null;
    membersOfLastParent?: boolean;
  }) {
    const list: (TeamMember & {
      memberIndex: number;
      nestingLevel?: number;
      flatIndex: number;
      membersOfLastParent: boolean;
      parentMember?: TeamMember;
      autoRefillCurrencies: Currency[];
    })[] = [];

    members?.forEach((member, memberIndex) => {
      const autoRefillCurrencies = Object.keys(
        member.cardBalance
      ) as Currency[];

      list.push(
        nestedFreeze({
          ...member,
          members: [],
          memberIndex,
          membersOfLastParent,
          flatIndex: flatIndex++,
          nestingLevel:
            member.members?.length || nestingLevel > 1
              ? nestingLevel
              : undefined,
          parentMember,
          autoRefillCurrencies,
        }),
        ...this.flatMembers({
          members: member.members,
          membersOfLastParent: memberIndex === members.length - 1,
          flatIndex,
          nestingLevel: nestingLevel + 1,
          parentMember: member,
        })
      );
    });

    return list;
  }

  private copyLinkClipboard() {
    copyTextClipboard(this.inviteUrl);

    this.$notify({
      type: "info",
      title: this.$vuetify.lang.t("$vuetify.info.copied"),
    });
  }

  private editTeamMember({ email, note }: { email: string; note?: string }) {
    this.editedTeamMember.email = email;
    this.editedTeamMember.note = note || "";
  }

  private async updateTeamMemberNote({
    email,
    note,
  }: {
    email: string;
    note: string;
  }) {
    this.updatingEmails.push(email);

    try {
      await this.updateTeamMemberAction({
        email,
        label: note,
        secondFactorCode: this.editedTeamMember.secondFactorCode,
      });

      this.editedTeamMember.email = "";
      this.isShowConfirmSecondFactorCode = false;
      this.refreshTeamMembers();
    } finally {
      this.updatingEmails = this.updatingEmails.filter(
        (updatingEmail) => updatingEmail !== email
      );
    }
  }

  private onChangeTeamMemberNote({
    email,
    note,
  }: {
    email: string;
    note: string;
  }) {
    if (this.profileSecondFactorEnabled) {
      this.confirmSecondFactorCode = {
        action: "note",
        data: {
          email,
          note,
        },
      };
    } else {
      this.updateTeamMemberNote({
        email,
        note,
      });
    }
  }

  private onChangeTableHeaderValue(value: TableHeaderValue) {
    this.topBarTableHeaderValue = value;
  }

  private confirmSecondFactor() {
    if (!this.confirmSecondFactorFormRef?.validate()) return;

    if (this.confirmSecondFactorCode?.action === "note") {
      this.updateTeamMemberNote(this.confirmSecondFactorCode.data);

      return;
    }

    if (this.confirmSecondFactorCode?.action === "delete") {
      this.deleteTeamMember(this.confirmSecondFactorCode.data);

      return;
    }
  }

  private getTeamMemberStatus({ enabled }: TeamMember) {
    if (!enabled) {
      return {
        text: this.$vuetify.lang.t("$vuetify.team.status.deleted"),
        color: "error",
      };
    }

    return {
      text: this.$vuetify.lang.t("$vuetify.team.status.active"),
      color: "success",
    };
  }

  private getTeamMemberName({
    firstName,
    lastName,
    email,
  }: Pick<TeamMember, "firstName" | "lastName" | "email">) {
    return getFullName({
      firstName,
      lastName,
      fallback: email,
    });
  }

  private onSuccessSetCardIssueLimit() {
    this.refreshTeamMembers();
  }

  private onSuccessTransferFunds() {
    this.refreshTeamMembers();
  }

  private async deleteTeamMember({ email }: { email: string }) {
    this.deletingEmails.push(email);

    try {
      await this.updateTeamMemberAction({
        active: false,
        email,
        secondFactorCode: this.editedTeamMember.secondFactorCode,
      });

      this.isShowConfirmSecondFactorCode = false;
      this.refreshTeamMembers();
    } finally {
      this.deletingEmails = this.deletingEmails.filter(
        (deletingEmail) => deletingEmail !== email
      );
    }
  }

  private onConfirmDeleteTeamMember(email: string) {
    this.confirmDialog = {
      showed: true,
      loading: false,
      data: { action: "delete", email },
    };
  }

  private onConfirmDialog() {
    if (this.confirmDialog.data.action === "delete") {
      this.onDeleteTeamMember(this.confirmDialog.data.email);
    }

    this.confirmDialog.showed = false;
  }

  private async onDeleteTeamMember(email: string) {
    if (this.profileSecondFactorEnabled) {
      this.confirmSecondFactorCode = {
        action: "delete",
        data: {
          email,
        },
      };
    } else {
      this.deleteTeamMember({
        email,
      });
    }
  }

  protected refreshTeamMembers(): void {
    this.teamHierarchyReloadKey++;
  }

  protected async fetchTeamMembers(): Promise<void> {
    await this.fetchTeamHierarchyAction({
      fromCache: false,
    });
  }

  private async fetchTeamInvite(options: { refresh?: boolean } = {}) {
    const { refresh = false } = options;

    if (this.inviteLink.loading || this.userIsGhostGetter) return;

    this.inviteLink.loading = true;

    try {
      const { link, expireAt } = await (!refresh
        ? this.fetchTeamInviteAction()
        : this.refreshTeamInviteAction());

      const [code] = link.split("=").reverse();

      this.inviteLink.code = code;
      this.inviteLink.expireAt = expireAt - 100;
      this.inviteLink.loading = false;
    } catch (error) {
      this.inviteLink.loading = false;
    }
  }

  private formatMoney(value: number, currency: Currency) {
    return formatMoney({
      value,
      currency,
      locale: this.$vuetify.lang.current,
    });
  }

  private async initWebPreferences() {
    this.teamHierarchyLoading = true;

    await this.fetchUserWebPreferencesAction({
      key: UserWebPreferencesKey.TEAM,
    });

    this.teamHierarchyLoading = false;

    const { value } = this.userWebPreferencesGetter({
      key: UserWebPreferencesKey.TEAM,
    });

    const userWebPreferences = value as Partial<{
      topBarTableHeaderValue: TableHeaderValue;
      itemsPerPage: number;
    }> | null;

    this.itemsPerPage = userWebPreferences?.itemsPerPage ?? this.itemsPerPage;
    this.topBarTableHeaderValue = this.topBarTableHeaderValue.map(
      ({ columnName, text, selected }) =>
        Object.freeze({
          columnName,
          text,
          selected:
            userWebPreferences?.topBarTableHeaderValue?.find(
              (item) => item.columnName === columnName
            )?.selected ?? selected,
        })
    );

    this.$watch(
      () => {
        return this.topBarTableHeaderValue;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    this.$watch(
      () => {
        return [this.itemsPerPage, this.forceUpdateUserWebPreferencesKey].join(
          "-"
        );
      },
      debounce(() => {
        this.updateUserWebPreferencesAction({
          key: UserWebPreferencesKey.TEAM,
          value: {
            topBarTableHeaderValue: this.topBarTableHeaderValue,
            itemsPerPage: this.itemsPerPage,
          },
        });
      }, 500)
    );
  }

  private created() {
    this.topBarTableHeaderValue = this.topBarTableHeaderItems.map(
      ({ value, text }) =>
        Object.freeze({
          columnName: value,
          text,
          selected: true,
        })
    );
  }

  private initTeamMembersWatcher() {
    let requestKey = Date.now();

    const fetchTeamMembers = async () => {
      if (this.loadingTeam) return;

      const oldRequestKey = requestKey;

      await this.fetchTeamMembers();

      if (requestKey !== oldRequestKey) {
        fetchTeamMembers();
      }
    };

    this.$watch(
      () => {
        return this.teamHierarchyReloadKey;
      },
      () => {
        requestKey++;
        fetchTeamMembers();
      },
      { immediate: true }
    );
  }

  private async mounted() {
    await this.initWebPreferences();
    this.initTeamMembersWatcher();

    if (this.canViewInviteUrl) {
      this.fetchTeamInvite();
    }
  }
}
