import { TeamMember } from "@/types/team";
import { Role } from "@/types/role";
import { Currency } from "@/types/currency";
import { cleanRole } from "@/lib/role";

const mockTeamMemberHierarchy: TeamMember = {
  authority: cleanRole(Role.ROLE_TEAMLEAD),
  firstName: "test22",
  lastName: "testwww123",
  email: "test@test.ru",
  enabled: true,
  balance: {
    [Currency.EUR]: 13.73,
    [Currency.USD]: 113,
  },
  cardBalance: {
    [Currency.EUR]: 50,
    [Currency.USD]: 207,
  },
  spend: {
    [Currency.EUR]: 101.96,
    [Currency.USD]: 98.8,
  },
  totalUsd: 389.7885536308,
  numberOfCards: 218,
  numberOfActiveCards: 13,
  cardIssueLimit: 230,
  autoRefillSettings: {},
  members: [
    {
      authority: cleanRole(Role.ROLE_MEDIABUYER),
      email: "test666@test.ru",
      avatar: "smp-23",
      enabled: true,
      balance: {
        [Currency.EUR]: 0,
        [Currency.USD]: 0,
      },
      cardBalance: {
        [Currency.EUR]: 0,
        [Currency.USD]: 0,
      },
      spend: {
        [Currency.EUR]: 0,
        [Currency.USD]: 0,
      },
      totalUsd: 0,
      numberOfCards: 0,
      numberOfActiveCards: 0,
      cardIssueLimit: 200,
      members: [],
      teamlead: false,
      autoRefillSettings: {},
    },
  ],
  teamlead: true,
};

export default mockTeamMemberHierarchy;
